
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Intro'
})
export default class Intro extends Vue {
  // 轮播图坐标
  rollIndex = 0
  // 定时器
  interval = {} as any

  descriptions = [
    {
      title: '轻量化快速微服务开发',
      des: 'XBGcoding是一个轻量化快速微服务开发平台，它基于podman容器技术，为开发人员提供了强大的devops工具和微服务快速开发工具。'
    },
    {
      title: '快速初始化服务器podman服务',
      des: '使用xbgcoding，您可以快速初始化服务器podman服务，实现微服务的自动化打包和各种image部署。'
    },
    {
      title: '可视化界面操作',
      des: 'xbgcoding提供了可视化界面，可以在企业微服务众多时实现统一管理和权限管理。'
    },
    {
      title: '快速部署',
      des: 'xbgcoding还提供了images库，以协助企业快速部署注册中心、API网关、日志中心、安全中心等开源的微服务基础服务。与k8s相比，xbgcoding免去了繁重的安装配置和学习成本。'
    }
  ]

  mounted () {
    this.createInterval()
  }

  createInterval () {
    if (this.interval) clearInterval(this.interval)
    this.interval = setInterval(() => {
      this.animation(() => {
        if (this.rollIndex < 3) this.rollIndex++
        else this.rollIndex = 0
      })
    }, 3000)
  }

  subRollIndex () {
    this.animation(() => {
      if (this.rollIndex > 0) this.rollIndex--
      else this.rollIndex = 3
    })
    this.createInterval()
  }

  addRollIndex () {
    this.animation(() => {
      if (this.rollIndex < 3) this.rollIndex++
      else this.rollIndex = 0
    })
    this.createInterval()
  }

  handleClickRollNav (index: number) {
    this.animation(() => {
      this.rollIndex = index
    })
    this.createInterval()
  }

  // 动画效果
  animation (fun: Function) {
    const el = document.getElementsByClassName('intro-des')
    el[this.rollIndex].classList.remove('fade-in')
    el[this.rollIndex].classList.add('fade-out')
    const time = setTimeout(() => {
      fun()

      el[this.rollIndex].classList.remove('fade-out')
      el[this.rollIndex].classList.add('fade-in')
      clearTimeout(time)
    }, 1000)
  }
}
