
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Price'
})
export default class Price extends Vue {
  users = [
    {
      title: '个人用户',
      des: '永久免费',
      price: '免费',
      serverNums: '1台',
      app: '无限制',
      childrenUser: '无限制',
      technicalSupport: '技术社区与微信群支持',
      button: '免费使用'
    },
    {
      title: '企业用户',
      des: '限时体验试用',
      price1: 'devops功能（2万/年）',
      price2: '微服务开发（公测期免费）',
      serverNums: '无限制',
      app: '无限制',
      childrenUser: '无限制',
      technicalSupport: '有限在线培训及在线技术支持*',
      button: '升级'
    }
  ]
}
