
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Character'
})
export default class Character extends Vue {
  cards = [
    {
      img: 'sand-clock.png',
      des: '一键环境初始化'
    },
    {
      img: 'box.png',
      des: '一键打包部署'
    },
    {
      img: 'log.png',
      des: '一键实时日志'
    },
    {
      img: 'monitor.png',
      des: '一键容器监控'
    }
  ]
}
