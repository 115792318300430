
import { Component, Vue } from 'vue-property-decorator'

@Component({
  name: 'Footer'
})
export default class Footer extends Vue {
  handleClickShare () {
    (this as any).$clipboard('https://www.gdxbgkj.com/')
    this.$Message.success('复制分享链接成功, 快去分享给好友吧!')
  }
}
