
import { Component, Vue } from 'vue-property-decorator'
import HomePage from './components/home-page.vue'
import Intro from './components/intro.vue'
import System from './components/system.vue'
import Character from './components/character.vue'
import Security from './components/security.vue'
import Tool from './components/tool.vue'
import Price from './components/price.vue'
import Footer from './components/footer.vue'

@Component({
  name: 'Home',
  components: { HomePage, Intro, System, Character, Security, Tool, Price, Footer }
})
export default class Home extends Vue {
  // 防抖标记
  wheeFlag = true
  // 标记
  homeIndex = 0
  // 导航栏
  navTexts = ['首页', '简介', '体系', '特性', '安全保障', '工具', '价格']
  // 组件
  components = [HomePage, Intro, System, Character, Security, Tool, Price, Footer]

  mounted () {
    const rollDiv = document.getElementsByClassName('roll')[0] as HTMLElement

    window.addEventListener('wheel', (event) => {
      if (event.deltaY !== 0 && this.wheeFlag) {
        this.wheeFlag = false
        // 向下滚动
        if (this.homeIndex < 7 && event.deltaY > 0) {
          rollDiv.style.transform = `translate3d(0px, -${(this.homeIndex + 1) * rollDiv.clientHeight}px, 0px)`
          this.homeIndex++
        }
        // 向上滚动
        if (this.homeIndex > 0 && event.deltaY < 0) {
          rollDiv.style.transform = `translate3d(0px, -${(this.homeIndex - 1) * rollDiv.clientHeight}px, 0px)`
          this.homeIndex--
        }

        this.time()
      }
    })
  }

  handleClickAnchor (index: number) {
    if (index !== this.homeIndex) {
      const rollDiv = document.getElementsByClassName('roll')[0] as HTMLElement
      rollDiv.style.transform = `translate3d(0px, -${index * rollDiv.clientHeight}px, 0px)`
      this.homeIndex = index
    }
  }

  time () {
    // 防抖
    const time = setTimeout(() => {
      this.wheeFlag = true
      clearTimeout(time)
    }, 1000)
  }
}
